<template>
  <va-select
    v-model="selectedUser"
    :label="label"
    :options="usersList"
    :loading="loading || isLoading"
    :no-options-text="$t('layout.empty')"
    :disabled="disabled"
    :text-by="textBy"
    @update-search="asyncFindUsers"
    searchable
  />
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'user-bar',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    queries: {
      type: String,
      default: '',
    },
    textBy: {
      type: String,
      default: 'label',
    },
    allSelector: {
      type: Boolean,
      default: false,
    },
    initialUser: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data () {
    return {
      isLoading: false,
      isError: false,
      usersList: [],
      selectedUser: '',
      currentQuery: '',
    }
  },
  watch: {
    initialUser (val) {
      if (!val) return
      val.label = this.getUserLabel(val)
      this.selectedUser = val
    },
    selectedUser (val) {
      this.userSelected(val)
    },
    'currentUser.permissions' () {
      this.initialData()
    },
  },
  created () {
    this.initialData()
  },
  methods: {
    fixData (users) {
      for (const t of users) {
        t.label = this.getUserLabel(t)
      }
      return users
    },
    getUserLabel (user) {
      return `${user.name}`
    },
    initialData () {
      this.asyncFindUsers('&sort=name&direction=asc')
    },
    async asyncFindUsers (query) {
      if (this.queries) {
        query += `&${this.queries}`
      }

      query = encodeURI(query)
      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }

      await this.asyncFind(query, 'users')
      if (this.isError) {
        return this.asyncFindUsers(query)
      }
    },
    async asyncFind (query, route) {
      this.isLoading = true
      this.isError = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }
      if (!query.includes('&limit=')) {
        query += '&limit=30'
      }
      this.currentQuery = query

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading = false
        this.isError = true
        return
      }

      if (query !== this.currentQuery) return
      this.usersList = this.fixData(response.data.data) || []
      this.isLoading = false
    },
    userSelected (user) {
      this.selectedUser = user
      this.$emit('selected-user', this.selectedUser)
    },
  },
}
</script>
